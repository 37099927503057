<template>
  <Defaultlayout :userprofile="userprofile">
    <PageSlider></PageSlider>
    <section class="about-us">
      <div class="container">
        <h1 class="page-heading">Location</h1>
        <div class="card">
          <div class="card-body">
            <div class="map-section">
              <div class="row">
                <div class="col-md-5 col-xl-4 shop-details-col">
                  <div class="shop-listing">
                    <!-- <div class="shop-details">
                      <h3>India Bazaar Plano (Since 2004) – Sun-Thu: 9am-9pm | Fri & Sat: 9am-10pm</h3>
                      <div class="address">
                        <p>832 W Spring Creek Pkwy, Ste# 100 South east corner of Spring Creek & Alma Plano TX 75023<br> United States</p>
                      </div>
                      <a class="link" href="#">View on map</a>
                    </div>

                    <div class="shop-details">
                      <h3>Richardson (since 2011) EVERYDAY: 10am-9pm</h3>
                      <div class="address">
                        <p>1425 E. Beltline rd. Richardson Richardson TX 75081<br> United States</p>
                      </div>
                      <a class="link" href="#">View on map</a>
                    </div>

                    <div class="shop-details">
                      <h3>Frisco ( since 2014) – Everyday: 8am to 9pm</h3>
                      <div class="address">
                        <p>8998 Preston Road Frisco TX 75034<br> United States</p>
                      </div>
                      <a class="link" href="#">View on map</a>
                    </div>

                    <div class="shop-details">
                      <h3>India Bazaar West Plano (Open Since 2017)- Everyday: 10am to 9pm</h3>
                      <div class="address">
                        <p>8450 Angels Dr Plano Texas 75024<br> United States</p>
                      </div>
                      <a class="link" href="#">View on map</a>
                    </div>

                    <div class="shop-details">
                      <h3>India Bazaar Irving (Since 2009) – EVERYDAY: 8am-10pm</h3>
                      <div class="address">
                        <p>8600 N MacArthur Blvd North east corner of MacArthur & Valley Ranch Irving TX 75063<br> United States</p>
                      </div>
                      <a class="link" href="#">View on map</a>
                    </div>

                    <div class="shop-details">
                      <h3>India Bazaar Lewisville: Everyday:10am-9pm.</h3>
                      <div class="address">
                        <p>2151 S Edmonds Lane Lewisville Texas 75067<br> United States</p>
                      </div>
                      <a class="link" href="#">View on map</a>
                    </div>

                    <div class="shop-details">
                      <h3>South Irving (since 2013) EVERYDAY: 10am-9pm</h3>
                      <div class="address">
                        <p>535 W Airport freeway 110-B Irving TX 75062<br> United States</p>
                      </div>
                      <a class="link" href="#">View on map</a>
                    </div> -->

                    <div class="shop-details">
                      <h3>Gharbazaar Return Center <br> Wednesday to Monday <br> 7am - 12pm <br> Tuesday - Closed</h3>
                      <div class="address">
                        <p>1640 FM 423. Little Elm Texas 75036<br> USA</p>
                      </div>
                      <a class="link" href="#">View on map</a>
                    </div>                                                     

                    </div>

                </div>
                <div class="col-md-7 col-xl-8">
                  <div>
                    <GmapMap :center="center" :zoom="10" id="map">
                      <GmapMarker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m">
                      </GmapMarker>
                    </GmapMap>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Defaultlayout>
</template>
<script>
import Defaultlayout from "../../layouts/Defaultlayout";
import PageSlider from "../pages/PageSlider";
export default {
  name: "Location",
  components: {
    Defaultlayout,
    PageSlider,
  },
  data() {
    return {
      loading: true,
      userprofile: null,
      center: { lat: 33.055286, lng: -96.708891 },
      // markers: [
      // {
      //   label: 'test',
      //   lat: 33.055286,
      //   lng: -96.70889,
      // },
      // {
      //   label: 'test',
      //   lat: 32.947290,
      //   lng: -96.698954,
      // },
      // {
      //   label: 'test',
      //   lat: 33.152496,
      //   lng: -96.802524,
      // },
      // {
      //   label: 'test',
      //   lat: 33.0933291,
      //   lng: -96.8018608,
      // },{
      //   label: 'test',
      //   lat: 32.9233418,
      //   lng: -96.953542
      // },{
      //   label: 'test',
      //   lat: 33.0069464,
      //   lng: -97.005118
      // },{
      //   label: 'test',
      //   lat: 32.8354637,
      //   lng: -96.945619
      // },{
      //   label: 'test',
      //   lat: 33.1653465,
      //   lng: -96.890629
      // }
      // ],
      markers: [
        {
          label: 'test',
          lat: 33.15532432877855,
          lng: -96.70889,
        },
      ],
    };
  },
  mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
  },
};
</script>
